<template>
    <div>
        <div></div>
        <footer class="mt-12 pt-2.5 pb-14 text-xs border-t border-solid border-roxo">
            coordsgo.com - <strong v-long-press='() => getRouter()'>Coords GO</strong>&nbsp;
            <small>v {{ version }}</small><br />

            <div class="flex justify-center">
                <div class="mt-2.5 mr-1">
                    <a class="no-underline" href="https://www.instagram.com/coordsgo/" target="_black">
                        <img class="mt-2.5 mr-1 h-10" src="../assets/insta.png" />
                    </a>
                </div>
                <div class="mt-2.5 mr-1">
                    <a class="no-underline" href="https://t.me/coordsgo" target="_black">
                        <img class="mt-2.5 mr-1 h-10" src="../assets/telegram-communications-icons-24.png" />
                    </a>
                </div>
                <div class="mt-2.5 mr-1">
                    <a class="no-underline" href="https://discord.gg/ymTHBRprhC" target="_black">
                        <img class="mt-2.5 mr-1 h-10" src="../assets/discord-logo.png" />
                    </a>
                </div>
            </div>
            <div class="mt-4 text-s">
                Coords GO não está vinculado oficialmente a Pokémon GO e nosso propósito é apenas informar como qualquer
                Wiki. Todas as imagens, nomes e personagens que aparecem neste App são propriedade de seus respectivos
                devidos: ©Niantic, Inc. | ©Pokémon | ©Nintendo | ©Creatures, Inc. | ©Game Freak, Inc.
            </div>
        </footer>
    </div>
</template>

<script>
import { Capacitor } from '@capacitor/core'
import { AdMob, BannerAdSize, BannerAdPosition, BannerAdPluginEvents } from '@capacitor-community/admob'
import Package from '../../package.json'

export default {
    data: () => ({
        version: '',
        login: null
    }),
    methods: {
        async initializeAdMob() {
            await AdMob.initialize()
            AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
                console.log('Banner Ad Loaded')
            })
            AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (error) => {
                console.error('Failed to load Banner Ad:', error)
            })
        },
        showTabBarBanner() {
            const options = {
                adId: 'ca-app-pub-9214659967456120/1612326038',
                adSize: BannerAdSize.BANNER,
                position: BannerAdPosition.BOTTOM_CENTER,
                margin: 2,
            }
            AdMob.showBanner(options)
        },
        getRouter() {
            this.$router.push('/login')
        }
    },
    async mounted() {
        if (Capacitor.isNativePlatform()) {
        await this.initializeAdMob()
        }

        if (localStorage.getItem('_l')) {
            this.login = JSON.parse(localStorage.getItem('_l'))
        }
        this.version = Package.version
        if (!this.login || this.login?.Interstitial === '1') {
            if (Capacitor.isNativePlatform()) {
            this.showTabBarBanner()
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
