<template>
  <div class="flex items-center">
    <div class="flex">
      <Slide :closeOnNavigation="true" width="200" @openMenu="handleOpenMenu" @closeMenu="handleCloseMenu">
        <tempPt />
        <small class="small">v {{ version }}</small>
      </Slide>
       <div class="overlay" v-if="open"></div>
      <router-link
        class="h-9"
        :to="'/'"
      >
        <img class="h-9 w-9" src="../assets/favicon-128x128.png" />
      </router-link>
    </div>

    <div class="flex justify-end flex-1">
      <img
        :class="{ 'border-cinza' : $i18n.locale === 'pt-br' }"
        class="h-7 rounded-full border-2 border-solid border-white cursor-pointer mx-2.5"
        src="../assets/i18n/brasil.png"
        @click.prevent="setLanguage('', 'pt-br')"
      />
      <img
        :class="{ 'border-cinza' : $i18n.locale === 'es-es' }"
        class="h-7 rounded-full border-2 border-solid border-white cursor-pointer mx-2.5"
        src="../assets/i18n/espanha.png"
        @click.prevent="setLanguage('', 'es-es')"
      />
      <img
        :class="{ 'border-cinza' : $i18n.locale === 'en-us' }"
        class="h-7 rounded-full border-2 border-solid border-white cursor-pointer mx-2.5"
        src="../assets/i18n/estados-unidos.png"
        @click.prevent="setLanguage('', 'en-us')"
      />
    </div>

  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'
import tempPt from './pt.vue'
import Package from '../../package.json'

export default {
  components: {
    Slide,
    tempPt
  },
  data: () => ({
    version: '',
    open: false
  }),
  methods: {
    setLanguage(url, lang) {
      this.$i18n.locale = lang
      localStorage.setItem('_idioma', lang)
    },
    handleOpenMenu() {
      this.open = true
    },
    handleCloseMenu() {
      this.open = false
    }
  },
  created() {
    this.version = Package.version
    if (!localStorage.getItem('_idioma')) {
      localStorage.setItem('_idioma', this.$i18n.locale)
    } else {
      this.$i18n.locale = localStorage.getItem('_idioma')
      switch (localStorage.getItem('_idioma')) {
        case 'pt-br':
          if (this.$route.path !== '/') {
            this.$i18n.locale = 'pt-br'
          }
          break
        case 'en-us':
          if (this.$route.path !== '/en') {
            this.$i18n.locale = 'en-us'
          }
          break
        case 'es-es':
          if (this.$route.path !== '/es') {
            this.$i18n.locale = 'es-es'
          }
          break
      }
    }
  }
}
</script>

<style lang="scss">
.bm-burger-button {
  position: relative !important;
  top: 3px  !important;
  left: 0px  !important;
  margin-right: 15px;
}

.bm-burger-bars {
  background-color: #673bb7  !important;

}
.bm-menu {
  background: rgb(103,59,183);
  background: radial-gradient(circle, rgba(103,59,183,1) 0%, rgba(82,20,194,1) 100%);
  padding-top: 20px !important;
}
.bm-item-list {
  margin-left: 0;
  a {
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }
}

.small {
  font-size: 10px;
  display: flex;
}

.overlay {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #673bb791;
    z-index: 10;
    overflow: hidden;
}

.bm-overlay {
   height: 100%;
   overflow-y: hidden;
  background: rgba(255, 255, 255, 0.3) !important;
}
</style>
