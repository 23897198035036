<template>
    <div id="app">
        <idioma />
        <div>
            <router-view :remote="remote" />
        </div>
        <footer-base />
    </div>
</template>

<script>
import { AppLauncher } from '@capacitor/app-launcher'
import Idioma from './Idioma'
import FooterBase from './FooterBase'

export default {
    name: 'App',
    props: ['remote'],
    components: {
        Idioma,
        FooterBase
    },
    data: () => ({
        open: false
    }),
    methods: {
        touchHandler() {
            this.$router.push('/gpx')
        },
        async checkCanOpenUrl() {
            const value = await AppLauncher.openUrl({ url: 'https://play.google.com/store/apps/details?id=br.com.sistemaweb.gpxgo' })
            console.log('Can open url: ', value)
        }
    },
}
</script>

<style lang="scss" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 20px auto;
    max-width: 400px;
    padding: 12px;
    font-size: 14px;
    user-select: none;
}

a {
    text-decoration: none;
    color: #fff;
}

#nav {
    margin: 10px 0 0 0;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;

    a {
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.router-link-exact-active {
            color: #673bb7;
        }
    }

    .gpx {
        color: #673bb7;
        background: #fff;
        border: 1px solid #ccc;
        box-sizing: border-box;
        width: 90px !important;
        margin-right: 10px;
        border-radius: 5px;
        display: inline-grid;

        &.router-link-exact-active {
            background: #673bb7;
            color: #fff;

            .event {
                color: #fff;
                background: #ffc107;
            }
        }
    }
}

.n {
    font-size: 12px;
}

#nav-2,
#nav-3 {
    padding: 5px 0 0 0;
    display: flex;
    justify-content: space-between;

    a {
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.router-link-exact-active {
            color: #673bb7;
        }
    }

    .gpx {
        color: #673bb7;
        background: #fff;
        border: 1px solid #ccc;
        box-sizing: border-box;
        width: 85px;
        border-radius: 5px;
        position: relative;

        &.router-link-exact-active {
            background: #673bb7;
            color: #fff;

            .event {
                color: #fff;
                background: #ffc107;
            }
        }
    }

    .gpx1 {
        width: 85px;
    }
}

.event {
    content: '50%';
    position: absolute;
    top: 5px;
    right: -10px;
    text-align: center;
    padding: 1px 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px;
    text-shadow: 0 0 3px rgb(0 0 0 / 50%);
    transform: rotate(45deg);
    box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
    width: 42px;
    font-size: 7px;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #673bb7;
    text-transform: uppercase;
}

.breve {
    background: #eee !important;
}
</style>

